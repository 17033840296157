import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import { createRouter, createWebHistory } from "vue-router";
import NotFound from "../views/NotFound.vue";
import store from "@/store";
import TokenService from "@/common/token.service";
import BaseRoutes from "./BaseRoutes";
import MirorRoutes from "./MirorRoutes";
import { publicPages } from "@/common/publicPages.js";

function getRoutes() {
  const businessSlug = TokenService.getToken("business_slug");
  const useMirorRoutes =
    businessSlug === process.env.VUE_APP_BUSINESS_SLUG_MIROR;
  return [
    ...(useMirorRoutes ? MirorRoutes : BaseRoutes),
    {
      path: "/too-many-requests",
      name: "TooManyRequests",
      component: () =>
        import(
          /* webpackChunkName: "toomanyrequests" */ "../views/TooManyRequests.vue"
        ),
    },
    {
      path: "/maintenance",
      name: "Maintenance",
      component: () =>
        import(
          /* webpackChunkName: "maintenance" */ "../views/Maintenance.vue"
        ),
    },
    {
      path: "/:catchAll(.*)*",
      name: "NotFound",
      component: NotFound,
    },
  ];
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: getRoutes(),
});

async function validateBusinessSlug(to, next) {
  try {
    const { data } = await ApiService.get(apiResource.checkBusinessSlug, {
      slug: to.params.business,
    });

    if (data.status) {
      // Save validated business data
      TokenService.saveToken("business_slug", data.data.slug);
      TokenService.saveToken("business_name", data.data.name);
      TokenService.saveToken("business_location", data.data.location);
      TokenService.saveToken("business_category", data.data.category);
      TokenService.saveToken("business_logo", data.data.logo);
      TokenService.saveToken("business_src", data.data.src);
      TokenService.saveToken("login_type", data.data.login_type);
      TokenService.saveToken("show_register_win", data.data.show_register_win);

      store.commit("setBusinessSlug", data.data.slug);
      store.commit("setBusinessName", data.data.name);
      store.commit("setBusinessLocation", data.data.location);
      store.commit("setBusinessCategory", data.data.category);
      store.commit("setBusinessLogo", data.data.logo);
      store.commit("setBusinessSrc", data.data.src);
      store.commit("setLoginType", data.data.login_type);
      store.commit("setShowRegisterWin", data.data.show_register_win);

      return true; // Business is valid
    } else {
      // Redirect to NotFound if business is invalid
      next({
        name: "NotFound",
        params: { catchAll: to.params.business },
      });
      return false;
    }
  } catch (error) {
    // Handle API errors and redirect to NotFound
    next({
      name: "NotFound",
      params: { catchAll: to.params.business },
    });
    return false;
  }
}

router.beforeEach(async (to, from, next) => {
  if (to.params.business) {
    const isValid = await validateBusinessSlug(to, next);
    if (!isValid) return; // If validation failed, stop navigation
  }

  if (publicPages.includes(to.name)) {
    // Allow public pages without authentication
    return next();
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Require authentication for protected routes
    if (!store.getters.isAuthenticated) {
      return next({
        name: "Register",
        params: { business: to.params.business },
        query: { redirect: to.fullPath }, // Save the target page for post-login redirect
      });
    }
  }

  // Proceed to the requested page
  return next();
});

export default router;
