export default [
  {
    path: "/",
    name: "Home",
    meta: {
      requiresAuth: false,
    },
    component: () => import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
  },
  {
    path: "/:business",
    name: "Register",
    meta: {
      requiresAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: "register" */ "@/views/Register.vue"),
  },
  {
    path: "/:business/miror-auth",
    name: "MirorAuth",
    meta: {
      requiresAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: "mirorauth" */ "@/views/Miror/Auth.vue"),
  },
  {
    path: "/:business/lobby",
    name: "Lobby",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "lobby" */ "@/views/Lobby.vue"),
  },
  {
    path: "/:business/game/:schedule_id/:game/:redirect?/:game_session_id?",
    name: "Game",
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "game" */ "@/views/Game.vue"),
  },
  {
    path: "/:business/leaderboard/:schedule_id",
    name: "Leaderboard",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "leaderboard" */ "@/views/Leaderboard.vue"),
  },
  {
    path: "/:business/rewards",
    name: "Rewards",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "rewards" */ "@/views/Rewards.vue"),
  },
  {
    path: "/:business/limit-reached",
    name: "UserLimit",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "userlimit" */ "@/views/UserLimit.vue"),
  },
];
