import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Toast, { POSITION } from "vue-toastification";
import LoadScript from "vue-plugin-load-script";
import "vue-toastification/dist/index.css";
import "./assets/css/tailwind.css";
import ApiService from "@/common/api.service";
import vue3GoogleLogin from "vue3-google-login";
import * as Sentry from "@sentry/vue";
ApiService.init();

let CLIENT_ID = process.env.VUE_APP_GOOGLE_CLIENT_ID;

const app = createApp(App);

// Function to create or update the canonical tag
const updateCanonicalTag = (url) => {
  let link = document.querySelector("link[rel='canonical']");
  if (!link) {
    link = document.createElement("link");
    link.setAttribute("rel", "canonical");
    document.head.appendChild(link);
  }
  link.setAttribute("href", url);
};

// Ensure canonical tag updates on initial load
router.isReady().then(() => {
  const initialUrl = `${window.location.origin}${router.currentRoute.value.fullPath}`;
  updateCanonicalTag(initialUrl);
});

// Ensure we checked auth before each page load.
router.beforeEach((to, from, next) => {
  // window.webengage.options('webpush.disablePrompt', true);
  // window.webengage.options("safariWebPush", true);
  store.dispatch("addPageLoading");
  Promise.all([store.dispatch("checkAuth")]).then(next());
});

router.afterEach((to) => {
  const canonicalUrl = `${window.location.origin}${to.fullPath}`;
  updateCanonicalTag(canonicalUrl);
  setTimeout(() => store.dispatch("pageLoadingComplete"), 100);
});

const options = {
  // You can set your default options here
  position: POSITION.TOP_CENTER,
};

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/games.playda\.io/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(Toast, options);
app.use(store);
app.use(router);
app.use(LoadScript);
app.use(vue3GoogleLogin, {
  clientId: CLIENT_ID,
});
app.mount("#app");
